import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import GameView from './components/game/gameView';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { Provider} from 'jotai'
import Header from './components/header/header';
import { gameCatalog } from './game';
import { Home } from './components/Home/home';

function App() {

  // High level application settings
  // TODO: Initial values
  const currentLevel = useState(null)
  const currentIdx = useState(0)


  return (
   <div className='app'>

      <Provider>
        <Router>

            {/* Routes */}
            <Routes>

                <Route path = "*" element = {<><Header />
                </>
                } 
                />

            </Routes>
            <Routes>

              <Route path='/'
                element={
                  <Home/>
                }
              />
              
              {gameCatalog.map((game, idx) => {
                return <Route 
                  key = {idx}
                  path = {game.link}
                  element = {
                    <GameView
                      currentGame={game.gameFlow}
                    />
                  }
                />
              })}
              

            </Routes>

        </Router>
    </Provider>



   </div>
  );
}

export default App;
