import './home.css'


const locations = [
    'https://png2.cleanpng.com/sh/a5176bbcf8a82cc57436e61380c0352d/L0KzQYm3VcI5N5p2R91yc4Pzfri0jfllbJ1qRdNwZYOwfbbrifV3aZ0yeeRsaHn3dbT7lgJmNZdme9NtZT3khcX2hPV0c154ed54b36wRbO3gvIza2RnT6Q5MkOxRIqBVcc0PGc2TaQCNEm2Q4K8V8UxOV91htk=/kisspng-middle-ages-medieval-architecture-facade-autodesk-saloon-5b0bb2c3b72023.4985734615274933157501.png',
    'https://p1.hiclipart.com/preview/530/158/441/e-s-bones-i-brown-human-skeleton-kneeling-png-clipart.jpg'
]

const slogans = [
    'YOUR JOURNEY INWARD BEGINS',
    'OPEN YOUR MIND'
]


export function Home () {


    const locIdx = 1

    return <div className="home">


        {/* <div className='locationWrapper'> */}

        <Location src={locations[locIdx]}/>
        {/* </div> */}


        <div className='lowerBanner'>YOUR JOURNEY INWARD BEGINS</div>

    </div>



}


function Location({src}: {src: string}) {
    return <img className='location' src={src}/>

}