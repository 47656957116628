
import {atom} from 'jotai'
import { atomWithReducer } from 'jotai/utils'

import { GameFlow } from '../../game/types'
import { findFrameById } from '../../game/utilities/navigation'

export const userAtom = atom<UserProfile>({
    username: "Playa",
    profileImg: 'https://i1.sndcdn.com/artworks-000087535998-bv2o99-t500x500.jpg'
})

export const currentGameAtom = atom<GameFlow>([])

export const currentSectionAtom = atom<number>(0)

interface GameProgress {
    section: number
    frame: number
}
export const currentProgressAtom = atom<GameProgress>({section: 0, frame: 0})

type GameProgressActions = {type: 'GO_TO_NEXT_SECTION'} | {type: 'GO_TO_NEXT_FRAME'} | {type: "JUMP_TO_FRAME", frameUID: string}



export const progressDispatchAtom = atom(null, (get, set, action: GameProgressActions) => {
    const gameSpec = get(currentGameAtom)
    const gameProgress = get(currentProgressAtom)

    if (action.type === 'GO_TO_NEXT_FRAME') {

        const currentSection = gameSpec[gameProgress.section]

        // Can we go to next frame?
        if ((currentSection.screens.length-1) > gameProgress.frame) {
            gameProgress.frame += 1
            set(currentProgressAtom, {...gameProgress})
            return
        }

        // Otherwise; try to go to next section! 
        else {
            set(progressDispatchAtom, {type: "GO_TO_NEXT_SECTION"})
            return
        }
    }
    else if (action.type === 'GO_TO_NEXT_SECTION') {
        if (gameSpec.length-1 > gameProgress.section) {
            gameProgress.section += 1
            gameProgress.frame = 0
            set(currentProgressAtom, {...gameProgress})
        }
    }

    else if (action.type === "JUMP_TO_FRAME") {
        const jumpToPoint = findFrameById(action.frameUID, gameSpec)
        if (jumpToPoint) {
            gameProgress.section = jumpToPoint.section
            gameProgress.frame = jumpToPoint.frame
            set(currentProgressAtom, {...gameProgress})
            return
        }
    }

    else 
      throw new Error('unknown action type')
})


interface UserProfile {

    username: string,
    profileImg: string,
    themeSong?: string,

}
