

export enum SectionType {
    INTERLUDE,
    LEVEL,
}

/**
 * Need to decide what the pecking order on this is gonna be.... 
 * 
 * The "music" should be able to span multiple different "Sections".... 
 * 
 * A "Section" doesn't need to be a "level"..... 
 * 
 * 
 * What are some good names for these internal entries? 
 * 
 * There's 
 * - Game 
 * - Level
 * 
 * 
 */

export interface Entity {
    name: string,
    image: string,
}

export interface PlayerVsPlayerScreen {
    player1: Entity,
    player2: Entity,
}

export interface NPCDialog {
    person: Entity,
    dialog: string,
}

export interface UserOptionQuestion {
    prompt: string,
    options: {
        text: string,
        navToFrameUid: string,
    }[]
}

export interface TextInfo { 
    t: string,
    style?: 'LevelTitle'
}

export interface InputInfo {
    placeholder?: string,
    answer?: string,
}

export interface TextScreen {

    text?: TextInfo,
    uid?: string,
    image?: string,
    maxDuration?: number
    npcDialog?: NPCDialog
    userOptionQuestion?: UserOptionQuestion
    input?: InputInfo

}


export type InterludeScreen = TextScreen





export type InterludeSection = {
    type: SectionType.INTERLUDE,

    // Section music
    // TODO @Marcel: Make this more well structured
    music?: string

    // Text Batches
    screens: TextScreen[]

}


type LevelSection = {
    type: SectionType.LEVEL, 
    music?: string,
    // TODO: Level properties
}


/** Global game flow schematic */
export type GameSection = InterludeSection

export type GameFlow = Array<GameSection>


export type Game = {
    name: string
    link: string
    gameFlow: GameFlow
}