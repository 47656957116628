import { Entity, Game, GameFlow, SectionType } from "./types";


const cooper: Entity = {
    name: "Cooper",
    image: "/resources/cooper.jpeg"
}

const pie: Entity = {
    name: "Cherry", 
    image: "/resources/pie.jpeg"
}

const grace: Entity ={ 
    name: "Grace",
    image: "https://mir-s3-cdn-cf.behance.net/projects/404/aa5ff872481717.5be95761be97a.jpg"
}

const bjork: Entity = {
    name: "Bjork",
    image: "https://static.dw.com/image/41478168_702.jpg"
}

const jamesGuyFierie = {
    name: "James Guy Fierie",
    image: "https://retouchingcentral.com/wp-content/uploads/2019/03/matrix-10.gif",
}

const gordon: Entity = {
    name: "Gordon", 
    image: "/resources/gordon.jpeg"
}

const bigEd: Entity = {
    name: "Big Ed",
    image: "https://twinpeaksfreaks.files.wordpress.com/2014/06/ed2.jpg"
}

const james: Entity = {
    name: "James", 
    image: "/resources/james.jpeg"
}

const donna: Entity = {
    name: "Donna", 
    image: "/resources/donna.webp"
}

const bob: Entity = {
    name: "Bob", 
    image: "/resources/bob.webp"
}

const mandelbrot: Entity = {
    name: "Benoit Mandelbrot", 
    image: "/resources/mandelbrot.webp"
}

export const devLevel: GameFlow = [

    {
        type: SectionType.INTERLUDE,
        screens: [
            {text: {t: "Hello James... "}},
            {text: {t: "... "}},
            {text: {t: " James... "}},
            {text: {t: " Wake up James... "}},
            {
                image: '/resources/welcomeToTwinPeaks.jpg', 
                maxDuration: 2000
            },
            {
                image: '/resources/welcomeToTwinPeaks.jpg', 
                maxDuration: 2000,
                npcDialog: {
                    person: cooper,
                    dialog: "Welcome James. It's damn good to see you."
                }
            },

            {
                image: '/resources/welcomeToTwinPeaks.jpg', 
                maxDuration: 2000,
                npcDialog: {
                    person: cooper,
                    dialog: "I have a mission for you James. It's absolutely vital that you listen closely."
                }
            },
            {
                image: '/resources/welcomeToTwinPeaks.jpg', 
                maxDuration: 2000,
                npcDialog: {
                    person: pie,
                    dialog: "Come 'ere big boy"
                }
            },
            {
                image: '/resources/welcomeToTwinPeaks.jpg', 
                maxDuration: 2000,
                npcDialog: {
                    person: cooper,
                    dialog: "I'm sorry James... that mission will have to wait."
                }
            },
            {
                image: 'https://i.pinimg.com/originals/94/f6/18/94f6184fd7444cd29ad1793b953f47a5.gif', 
                maxDuration: 2000,
                npcDialog: {
                    person: james,
                    dialog: "It's okay. In the meantime, I'll be......"
                }
            },
            {text: {t: "Pt 1: Looking For Love <3", style: "LevelTitle"}},

            

            //... would be cool to have discussions possible
        ],
        music: "/audio/twinPeaksTheme.mp3"
    },
    {
        type: SectionType.INTERLUDE,
        screens: [
            {
                image: "https://www.seanmichaelragan.com/img/twin_peaks_bird.jpg",
            },
            {
                image: "https://img.freepik.com/premium-photo/room-with-guitar-poster-wall-that-says-word-it_960764-6050.jpg",
                npcDialog: {
                    person: bigEd,
                    dialog: "Hey Jimbo. Morning to ya."
                }
            },
            {
                image: "https://img.freepik.com/premium-photo/room-with-guitar-poster-wall-that-says-word-it_960764-6050.jpg",
                npcDialog: {
                    person: james,
                    dialog: "Omfg, please piss off"
                }
            },
            {
                npcDialog: {
                    person: james,
                    dialog: "Ugh, jeesus christ, wtf."
                }
            },
            {
                image: "https://m.media-amazon.com/images/I/61GDKZUAkaL._AC_UF894,1000_QL80_.jpg",
            },
            {
                image: "https://m.media-amazon.com/images/I/61GDKZUAkaL._AC_UF894,1000_QL80_.jpg",
                npcDialog: {
                    person: james,
                    dialog: "Damn baby,"
                }
            },
            {
                image: "https://m.media-amazon.com/images/I/61GDKZUAkaL._AC_UF894,1000_QL80_.jpg",
                npcDialog: {
                    person: james,
                    dialog: "My aloe is looking tight as hell this morning."
                }
            },
            {
                image: "https://m.media-amazon.com/images/I/61GDKZUAkaL._AC_UF894,1000_QL80_.jpg",
                npcDialog: {
                    person: james,
                    dialog: "Namaste and all that"
                }
            },
            {
                image: "https://m.media-amazon.com/images/I/61GDKZUAkaL._AC_UF894,1000_QL80_.jpg",
                npcDialog: {
                    person: james,
                    dialog: "Anyways, I should go to school so Ed will piss of."
                }
            },
        ]
    },
    {
        type: SectionType.INTERLUDE,
        music: '/audio/JustYouOld.mp3',
        screens: [
            {
                image: "https://retouchingcentral.com/wp-content/uploads/2019/03/matrix-10.gif",
                npcDialog: {
                    person: jamesGuyFierie,
                    dialog: "Hello. My name is James Guy Fierie."
                }
            },
            // TODO: Would be cool if the dialog boxes typed quickly
            {
                image: "https://retouchingcentral.com/wp-content/uploads/2019/03/matrix-10.gif",
                npcDialog: {
                    person: jamesGuyFierie,
                    dialog: "This is not part of the website. I'm in charge now."
                }
            },
            {
                image: "https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcT2xT7b1vWaZTfMAHqTaVf8H5MnYkECnp9s4slhh8JJrAssBq25MU-TlvaHGh2zsidQ92tB1CgGigzJaDA",
                npcDialog: {
                    person: jamesGuyFierie,
                    dialog: "Do you know who this is?"
                },
                input: {
                    placeholder: "Who is it?",
                    answer: "Robert Eggers"
                },
            },
            // {
            //     image: "https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcT2xT7b1vWaZTfMAHqTaVf8H5MnYkECnp9s4slhh8JJrAssBq25MU-TlvaHGh2zsidQ92tB1CgGigzJaDA",
            //     npcDialog: {
            //         person: jamesGuyFierie,
            //         dialog: "This man is a genius. We're going to find him."
            //     }
            // },
            {
                image: 'https://media.giphy.com/media/l1IY2yT4fAo66oDPG/giphy.gif',
                text: {t: "Donna..."}
            },
            {
                image: 'https://media.giphy.com/media/l1IY2yT4fAo66oDPG/giphy.gif',
                text: {t: "I wish i was not so weak..."}
            }
        ]
    },

]

export const searchingForLove: Game = {
    name: 'searching for love',
    link: 'searching_for_love',
    gameFlow: devLevel
}