
import { useState } from 'react'
import { GameSection, InterludeSection, NPCDialog, SectionType, TextScreen } from '../../game/types'
import './level.css'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentProgressAtom, progressDispatchAtom, userAtom } from '../state/state'
import { UserOptionQuestion } from '../../game/types'

interface LevelProps {

    currentSection: GameSection,
    goToNextSection: () => void

}

interface InterludeSectionProps {
    currentSection: InterludeSection,
    goToNextSection: () => void
}

const InterludeSectionView = ({currentSection, goToNextSection}: InterludeSectionProps) => {

    // Within a given section; we progress through internal screens. 

    // I don't LOVE that term. I think what would be better is to have something ENTIRELY capable of handling "level" like things. 

    // This should delegate to a sub-thing that handles the section rendering. 

    // TODO @Marcel: Move this somewhere internal higher up. 
    // const [sectionIdx, setSectionIdx] = useState(0)
    const progress = useAtomValue(currentProgressAtom)
    const dispatchProgress = useSetAtom(progressDispatchAtom)

    const progressScene = (e: any) => {
        e.preventDefault()

        if ((currentSection.screens.length-1)> progress.frame) {
            dispatchProgress({type: "GO_TO_NEXT_FRAME"})
        }
        else {
            goToNextSection();
        }

    }

    return <div
        onClick={progressScene}
    >
        {currentSection.screens && currentSection.screens[progress.frame] && <LevelSection info={currentSection.screens[progress.frame]}/>}

    </div>

}

const Level = (({currentSection, goToNextSection}: LevelProps) => {

    return <>
        {currentSection.type === SectionType.INTERLUDE ? 
            <InterludeSectionView currentSection={currentSection} goToNextSection={goToNextSection}/> : 
            <></>
        }
    
    </>

})

export default Level;

interface LevelSectionProps {
    info: TextScreen
}

const LevelSection = ({ info }: LevelSectionProps) => {

    return <div className="levelSection">

        {info.text && <div className={'interludeTextContainer' + (info.text.style === "LevelTitle" ? " levelTitle": "")}>{info.text.t}</div>}

        {/* Render img div */}
        {info.image &&
            
            <img className="interludeImgContainer"
                src = {info.image}
                alt='game img'
            />
        
        }

        <div className='dialogPositioner'>
            {info.npcDialog && <NPCDialogCard dialog={info.npcDialog}/>}
            {info.userOptionQuestion && <UserChoiceCard question={info.userOptionQuestion}/>}
        </div>

    </div>

}




const UserChoiceCard = ({ question} : { question: UserOptionQuestion}) => {

    const user = useAtomValue(userAtom)
    const dispatchProgress = useSetAtom(progressDispatchAtom)

    return <div className='npcDialogCard userChoiceCard'>

        <img
            src={user.profileImg}
        />

        <p>
            {question.prompt}
        </p>

        {question.options.map((option, idx) => {
            return <button key={idx} onClick={(e) => {
                e.preventDefault()
                dispatchProgress({type: "JUMP_TO_FRAME", frameUID: option.navToFrameUid})
            }}>
                {option.text}
            </button>
        })}

    </div>

}

const NPCDialogCard = ({ dialog} : { dialog: NPCDialog}) => {

    return <div className='npcDialogCard'>

        <img
            src={dialog.person.image}
        />

        <p>
            {dialog.dialog}
        </p>

    </div>

}