import { Entity, Game, GameFlow, SectionType } from "./types";

const lawyer: Entity = {
    name: "Lawyer",
    image: "https://justatic.com/profile-images/1492708-1664285635-s.jpeg"
}

const cooper: Entity = {
    name: "Cooper",
    image: "/resources/cooper.jpeg"
}

const pie: Entity = {
    name: "Cherry", 
    image: "/resources/pie.jpeg"
}

const grace: Entity ={ 
    name: "Grace",
    image: "https://mir-s3-cdn-cf.behance.net/projects/404/aa5ff872481717.5be95761be97a.jpg"
}

const bjork: Entity = {
    name: "Bjork",
    image: "https://static.dw.com/image/41478168_702.jpg"
}

const jamesGuyFierie = {
    name: "James Guy Fierie",
    image: "https://retouchingcentral.com/wp-content/uploads/2019/03/matrix-10.gif",
}

const gordon: Entity = {
    name: "Gordon", 
    image: "/resources/gordon.jpeg"
}

const bigEd: Entity = {
    name: "Big Ed",
    image: "https://twinpeaksfreaks.files.wordpress.com/2014/06/ed2.jpg"
}

const james: Entity = {
    name: "James", 
    image: "/resources/james.jpeg"
}

const donna: Entity = {
    name: "Donna", 
    image: "/resources/donna.webp"
}

const bob: Entity = {
    name: "Bob", 
    image: "/resources/bob.webp"
}

const mandelbrot: Entity = {
    name: "Benoit Mandelbrot", 
    image: "/resources/mandelbrot.webp"
}

export const flow: GameFlow = [

    {
        type: SectionType.INTERLUDE,
        screens: [
            {text: {t: "Humena humena..."}, image: 'https://media0.giphy.com/media/3otPoL98SINy7AIBig/200w.gif?cid=6c09b952dmdj8jzvunwswdc28f5ip3mrlqyjp7mgg3sabhy8&ep=v1_gifs_search&rid=200w.gif&ct=g'},
            {text: {t: "Oh sorry, didn't see you there!"}},
            {text: {t: "Wummena wummena..."}},
            {text: {t: "Let me tell you a tale."}},
            {text: {t: "It's one that you won't soon forget."}},
            {text: {t: "I'm the assceo and that's a bet ;)"}},
            {},
            {text: {t: " It was a dark and stormy night..."}},
            {
                image: 'https://64.media.tumblr.com/96bbfb0b0af9a6118d0049f63e25cf46/tumblr_oxsf7locdn1wzypxlo1_500.gif', 
                maxDuration: 2000
            },
            {
                npcDialog: {
                    person: bigEd,
                    dialog: "Well... i reckon we're fucked"
                },
                image: 'https://64.media.tumblr.com/96bbfb0b0af9a6118d0049f63e25cf46/tumblr_oxsf7locdn1wzypxlo1_500.gif', 
                maxDuration: 2000
            },
            {
                npcDialog: {
                    person: bigEd,
                    dialog: "Better get my dick and skee-dadle."
                },
                image: 'https://64.media.tumblr.com/96bbfb0b0af9a6118d0049f63e25cf46/tumblr_oxsf7locdn1wzypxlo1_500.gif', 
                maxDuration: 2000
            },
            {
                image: 'https://media4.giphy.com/media/h6x0ROdzJy4TKyUu1b/giphy.gif?cid=6c09b952esk0doy8miswfytlvmjvrza5h8mtml1zyna87w8v&ep=v1_gifs_search&rid=giphy.gif&ct=g',
                uid: 'splash into water from boat'
            }, 
            {
                image: 'https://i.pinimg.com/originals/83/b7/ac/83b7acd7cdb46af483af0b543043b232.gif'
            },
            {
                npcDialog: {
                    person: bigEd,
                    dialog: "Well, i recon everyone's just about dead."
                },
                image: 'https://i.pinimg.com/originals/83/b7/ac/83b7acd7cdb46af483af0b543043b232.gif'
            },
            {
                npcDialog: {
                    person: mandelbrot,
                    dialog: "...I'm not dead...."
                },
                image: 'https://i.pinimg.com/originals/83/b7/ac/83b7acd7cdb46af483af0b543043b232.gif'
            },
            {
                npcDialog: {
                    person: mandelbrot,
                    dialog: "I am very much.... ALIVE!"
                },
                image: 'https://i.pinimg.com/originals/83/b7/ac/83b7acd7cdb46af483af0b543043b232.gif'
            },
            {
                image: mandelbrot.image,
                userOptionQuestion: {
                    prompt: 'What would you like to do?',
                    options: [
                        {text: 'Punch him', navToFrameUid: 'splash into water from boat'},
                        {text: 'Kiss him', navToFrameUid: 'flame of of eternal love'},
                        {text: 'Hold him dearly like his mudda' , navToFrameUid: 'you make mandelbrot vomit all over in the water'}
                    ]
                }
            },
            {
                image: "https://viralviralvideos.com/wp-content/uploads/GIF/2015/04/Super-gross-throws-up-GIF.gif",
                uid: "you make mandelbrot vomit all over in the water"
            },
            {
                image: "https://viralviralvideos.com/wp-content/uploads/GIF/2015/04/Super-gross-throws-up-GIF.gif",
                npcDialog: {
                    person: lawyer,
                    dialog: "Whoa there sport! Looks like you're gonna need a lawyer!"
                }
            },
            {
                image: "https://viralviralvideos.com/wp-content/uploads/GIF/2015/04/Super-gross-throws-up-GIF.gif",
                npcDialog: {
                    person: lawyer,
                    dialog: "I got your ASS covered bro, TRUST me."
                }
            },
            {
                image: "https://geographical.co.uk/wp-content/uploads/shutterstock_1640168446-2-copy.jpg",
                userOptionQuestion: {
                    prompt: 'What would you like to do?',
                    options: [
                        {text: 'Hadle zone', navToFrameUid: 'todo'},
                        {text: 'Try to seduce lawyer', navToFrameUid: 'todo'},
                        {text: 'Sperm whale', navToFrameUid: 'todo'},
                        {text: 'Hawaii', navToFrameUid: 'todo'}
                    ]
                }
            },
            {
                image: "https://media.tenor.com/TPvdyjH6VmYAAAAM/heart-fire.gif",
                text: {t: "CONGRATULATIONS, YOU'RE GETTING MARRIED THE END. THE FLAME OF ETERNAL LOVE HAS FOUND A HOME FOREVER IN YOUR HEART"},
                uid: "flame of of eternal love"
            }
        ]
    }

]

export const nocturnalUndertones: Game = {
    name: 'nocturnal undertones',
    link: 'nocturnal_undertones',
    gameFlow: flow
}