import { GameFlow } from "../types";


export function findFrameById(id: string, game: GameFlow): {section: number, frame: number}|null {

  for (let sectionIdx=0; sectionIdx<game.length; sectionIdx++) {
    for (let frameIdx=0; frameIdx<game[sectionIdx].screens.length; frameIdx++) {
      // is this marked as we're looking for?
      if (game[sectionIdx].screens[frameIdx].uid === id) {
        return {section: sectionIdx, frame: frameIdx}
      }
    }
  }

  return null

}
