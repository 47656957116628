import { nocturnalUndertones } from "./nocturnalUndertones";
import { searchingForLove } from "./searchingForLove";
import { Game } from "./types";




export const gameCatalog: Game[] = [
    searchingForLove,
    nocturnalUndertones
]