import React, { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./header.css"
import { useAtom, useAtomValue } from 'jotai';
import { gameCatalog } from '../../game';


interface HeaderConfig {
    link: string,
    display: string,
}


const getGameLinks = (): HeaderConfig[] => {
    return gameCatalog.map(game => { return {
        link: game.link,
        display: game.name
    }})
}

const headerConfig: HeaderConfig[] = [
    {link: '', display: 'home'},
    ...getGameLinks()
]


interface HeaderProps {

}


const Header = (props: HeaderProps) => {


    const [ navOpen, setNavOpen ] = useState(false);

    const navigate = useNavigate();


    const toHome = () => {
        setNavOpen(false)
        navigate("/");
    }

    const toLink = (link: string) => {
        setNavOpen(false)
        navigate(link);
    }

    const toggleNav = () => {
        setNavOpen(!navOpen);
    }



    const pathRoot = '/' + window.location.pathname.split('/')[1];


    return <div>

        <div className="Header">

            {/* Logo */}
            <div className='LogoText' id="ayisen-logo" >ASS CEO</div>
            
            {/* Nav Opener */}
            <div className='headerOpener'  onClick={toggleNav}>🍑</div>

            {/* Side Nav */}
            <div className={'navBar' + (navOpen ? ' navBarOpen' : '')}>

                {headerConfig.map( (linkData, idx) => {
                    return <div className={'navOpt' + (pathRoot === linkData.link ? ' navOptActive' : '')}
                                onClick={()=>toLink(linkData.link)}
                            >
                        {linkData.display}

                    </div>
                } )}

                {/* TODO @Marcel: Add non link items here as well! (user settings, cart, etc) */}
                
            </div>

            {navOpen && 
                <div className='navBarExiter' onClick={()=>setNavOpen(false)}/>
            }
            
            
        </div>


    </div>

}


export default Header;