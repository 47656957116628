
import { useEffect, useState } from 'react'
import { devLevel } from '../../utilities/gameFlow'
import './gameView.css'
import Level from './level'
import { GameFlow } from '../../game/types'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { currentGameAtom, currentProgressAtom, currentSectionAtom, progressDispatchAtom } from '../state/state'



const useMusicPlayer = (src: string | undefined ) => {

    const [music, setMusic] = useState<HTMLAudioElement|null>(null);

    useEffect(() => {

        if (!src)
            return
        
        let audio = music ?? new Audio(src);

        if (audio.src !== src) {
            audio.src = src
        }

        audio.autoplay = true;
        audio.load();
        audio.play();

        setMusic(audio)

    }, [src, music])


    return music

}

const GameView =  ({currentGame: gameInPlay}: { currentGame: GameFlow}) => {

    const [currentGame, setCurrentGame] = useAtom(currentGameAtom)

    // When a game-view loads -> we set the activeGame atom
    useEffect(() => {
        setCurrentGame(gameInPlay)
    }, [])

    // High level game flow state
    const currentProgress = useAtomValue(currentProgressAtom)
    const dispatchCurrentProgress = useSetAtom(progressDispatchAtom)
    // const music = useMusicPlayer(currentGame[currentGameSection]?.music)

    // Function to smoothly fade audio
    // const fadeAudio = (audio: HTMLAudioElement, fadeLevel: number, fadeTime = 1000) => {

    //     const initialVolume = audio.volume;
    //     const fadeInterval = 200;
    //     const dimin = (initialVolume / fadeTime) * fadeInterval;

    //     let fader = setInterval( () => {

    //         // Only fade if past the fade out point or not at zero already
    //         if ((audio.currentTime >= fadeLevel) && (audio.volume > 0.0)) {
    //             if (audio.volume - dimin >= 0)
    //                 audio.volume -= dimin;
    //             else 
    //                 audio.volume = 0;
    //         }
    //         // When volume at zero stop all the intervalling
    //         if (audio.volume === 0.0) {
    //             clearInterval(fader);
    //         }
    //     }, fadeInterval);
    // }


    const currnetSection = currentGame?.[currentProgress.section] ?? gameInPlay[currentProgress.section]

    return <div>

        <Level 
            currentSection={currnetSection}
            goToNextSection={() => dispatchCurrentProgress({type: 'GO_TO_NEXT_SECTION'})}
        />

    </div>

}

export default GameView;